<template>
    <div class="order-details">
        <div class="row">
            <div class="col-md-8 ">
                <div class="date">
                    <span>
                        {{ $t('OrderDate') }}
                        <strong>{{ formatDate(OrderData.created_at) }}</strong>
                    </span>
                    <span v-if="OrderData.updated_at">
                        {{ $t('Date_of_last_update') }}
                        <strong>{{ formatDate(OrderData.updated_at) }}</strong>
                    </span>
                </div>
                <div class="stronge-title" v-if="ServiceDetails.is_price_visible == 0">
                    <h4 v-if="OrderData.price_offers?.length > 0">
                        {{ this.$i18n.locale == 'ar' ?
                            `الرجاء الموافقة على عرض السعر لاتمام طلبك`
                            :
                            `Please accept the price offer to complete your order`
                        }}
                    </h4>
                    <h4 v-else>
                        {{ this.$i18n.locale == 'ar' ?
                            `بانتظار انشاء عرض سعر`
                            :
                            `Waiting for a price offer to be created`
                        }}
                    </h4>
                </div>
                <div>
                    <ul class="nav nav-pills nav-pills-order-details" id="pills-tab4" role="tablist">
                        <li class="nav-item active" role="presentation" @click="index = 1">
                            <button data-index="P-D-1" class="nav-link py-3 active" id="v-pills-P-D-1-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-1" type="button" role="tab"
                                aria-controls="v-pills-P-D-1" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Service_details') }}</span>
                            </button>
                        </li>
                        <li class="nav-item" role="presentation" @click="index = 2">
                            <button data-index="P-D-2" class="nav-link py-3" id="v-pills-P-D-2-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-2" type="button" role="tab"
                                aria-controls="v-pills-P-D-2" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Service_requester') }}</span>
                            </button>
                        </li>
                        <li class="nav-item " role="presentation" @click="index = 3">
                            <button data-index="P-D-3" class="nav-link py-3 " id="v-pills-P-D-3-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-3" type="button" role="tab"
                                aria-controls="v-pills-P-D-3" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Server_Provider') }}</span>
                            </button>
                        </li>
                        <li class="nav-item " role="presentation" @click="index = 4" v-if="ExistAttachments">
                            <button data-index="P-D-4" class="nav-link py-3 " id="v-pills-P-D-4-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-4" type="button" role="tab"
                                aria-controls="v-pills-P-D-4" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Attachments') }}</span>
                            </button>
                        </li>
                        <li class="nav-item " role="presentation" @click="index = 5">
                            <button data-index="P-D-5" class="nav-link py-3 " id="v-pills-P-D-5-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-5" type="button" role="tab"
                                aria-controls="v-pills-P-D-5" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Correspondence') }}</span>
                            </button>
                        </li>
                        <li class="nav-item " role="presentation" @click="index = 6" v-if="Logs.length > 0">
                            <button data-index="P-D-6" class="nav-link py-3 " id="v-pills-P-D-6-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-6" type="button" role="tab"
                                aria-controls="v-pills-P-D-6" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Register_order') }}</span>
                            </button>
                        </li>
                        <li class="nav-item " role="presentation" @click="index = 7">
                            <button data-index="P-D-7" class="nav-link py-3 " id="v-pills-P-D-7-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-7" type="button" role="tab"
                                aria-controls="v-pills-P-D-7" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Payment') }}</span>
                            </button>
                        </li>
                        <li class="nav-item " role="presentation" @click="index = 8" v-if="OrderData.invoices?.lnegth > 0">
                            <button data-index="P-D-8" class="nav-link py-3 " id="v-pills-P-D-8-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-8" type="button" role="tab"
                                aria-controls="v-pills-P-D-8" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Invoices') }}</span>
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="title-nav">
                    <h2>{{ $t(NavTitle) }}</h2>
                    <div class="d-flex align-items-center">
                        <Show_Price :price_offers="OrderData?.price_offers"
                            v-if="OrderData?.price_offers?.length > 0" />
                    </div>
                </div>
                <div class="tab-content p-d mt-3" id="pills-tabContent">
                    <div class="tab-pane fade show active p-d-1" id="v-pills-P-D-1" role="tabpanel"
                        aria-labelledby="v-pills-P-D-1-tab">
                        <PD1 :step="OrderData.step"
                            :OrderInfo="OrderData.service" :attribute_values="OrderData.attribute_values" />
                    </div>
                    <div class="tab-pane fade show p-d-2" id="v-pills-P-D-2" role="tabpanel"
                        aria-labelledby="v-pills-P-D-2-tab">
                        <PD2 />
                    </div>
                    <div class="tab-pane fade show p-d-3" id="v-pills-P-D-3" role="tabpanel"
                        aria-labelledby="v-pills-P-D-3-tab">
                        <PD3
                            :service_provider="OrderData.service_provider" :branch="OrderData.branch" />
                    </div>
                    <div class="tab-pane fade show p-d-4" id="v-pills-P-D-4" role="tabpanel"
                        aria-labelledby="v-pills-P-D-4-tab">
                        <PD4 />
                    </div>
                    <div class="tab-pane fade show p-d-5" id="v-pills-P-D-5" role="tabpanel"
                        aria-labelledby="v-pills-P-D-5-tab">
                        <PD5 :chat_id="chat_id" />
                    </div>
                    <div class="tab-pane fade show p-d-6" id="v-pills-P-D-6" role="tabpanel"
                        aria-labelledby="v-pills--P-D-6-tab">
                        <PD6 :Logs="Logs" />
                    </div>
                    <div class="tab-pane fade show p-d-7" id="v-pills-P-D-7" role="tabpanel"
                        aria-labelledby="v-pills-P-D-7-tab">
                        <PD7
                            :payment_method="OrderData.payment_method" :paymentUrl="OrderData.paymentUrl" />
                    </div>
                    <div class="tab-pane fade show p-d-8" id="v-pills-P-D-8" role="tabpanel"
                        aria-labelledby="v-pills-P-D-8-tab">
                        <PD8 :invoices="OrderData.invoices" />
                    </div>
                </div>

            </div>
            <div class="col-md-4">
                <div class="package-order-info" :class="`package-order-info${$route.params.id}`">
                    <!-- <div class="package-order-info"> -->
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <h2 class="title">
                            {{ this.$i18n.locale == 'ar' ? 'حالة الطلب' : 'Order Status' }}
                        </h2>
                        <div class="status">
                            {{ OrderData.status?.show_name }}
                        </div>
                    </div>
                    <ul class="order-status">
                        <li :class="[active >= 1 ? 'active' : '']">
                            <span>1</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'بانتظار الموافقة' : 'Waiting for approval' }}</div>
                        </li>
                        <li :class="[active >= 2 ? 'active' : '']">
                            <span>2</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'جاري دراسة الحالة' : 'Case study underway' }}</div>
                        </li>
                        <li :class="[active >= 3 ? 'active' : '']">
                            <span>3</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'الموافقة على السعر' : 'Agree on the price' }}</div>
                        </li>
                        <li :class="[active >= 4 ? 'active' : '']">
                            <span>4</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'الدفع و الايصال' : 'Payment and receipt' }}</div>
                        </li>
                        <li :class="[active >= 5 ? 'active' : '']">
                            <span>5</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'التأكد من الدفع' : 'Verify payment' }}</div>
                        </li>
                        <li :class="[active >= 6 ? 'active' : '']">
                            <span>6</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'العمل على الخدمات' : 'Work on services' }}</div>
                        </li>
                        <li :class="[active >= 7 ? 'active' : '']">
                            <span>7</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'تسليم الخدمات' : 'Delivery of services' }}</div>
                        </li>
                    </ul>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
    data() {
        return {
            index: 1,
            OrderData: {},
            ServiceDetails: {},
            active: 1,
            ExistAttachments: false,
            Logs: [],
        };
    },
    components: {
        PD1: defineAsyncComponent(() => import('@/components/Services/Order/Tabs/Pd1.vue')),
        PD2: defineAsyncComponent(() => import('@/components/Services/Order/Tabs/Pd2.vue')),
        PD3: defineAsyncComponent(() => import('@/components/Services/Order/Tabs/Pd3.vue')),
        PD4: defineAsyncComponent(() => import('@/components/Services/Order/Tabs/Pd4.vue')),
        PD5: defineAsyncComponent(() => import('@/components/Services/Order/Tabs/Pd5.vue')),
        PD6: defineAsyncComponent(() => import('@/components/Services/Order/Tabs/Pd6.vue')),
        PD7: defineAsyncComponent(() => import('@/components/Services/Order/Tabs/Pd7.vue')),
        PD8: defineAsyncComponent(() => import('@/components/Services/Order/Tabs/Pd8.vue')),
    },
    computed: {
        NavTitle() {
            if (this.index == 1) {
                return `Service_details`
            }
            if (this.index == 2) {
                return `Service_requester`
            }
            if (this.index == 3) {
                return `Server_Provider`
            }
            if (this.index == 4) {
                return `Documents`
            }
            if (this.index == 5) {
                return `Correspondence`
            }
            if (this.index == 6) {
                return `Register_order`
            }
            if (this.index == 7) {
                return `Payment`
            }
            if (this.index == 8) {
                return `Invoices`
            }
        }
    },
    mounted() {
        let OrderId = this.$route.params.id;
        this.$store.dispatch('ServiceOrderDetails', OrderId).then(r => {
            this.OrderData = this.$store.state.ServiceOrderDetails;
            if(this.OrderData.user != undefined){
                window.localStorage.setItem('IsUser',true)
            }
            window.localStorage.setItem('price_offers', this.OrderData.price_offers);
            this.OrderData.service.attributes.forEach(el => {
                if (el.type == 'file') {
                    this.ExistAttachments = true;
                }
            });
            this.$store.dispatch('ServiceDetails', this.OrderData.service.id).then(r => {
                this.ServiceDetails = this.$store.state.ServiceData;
            })
            if (this.OrderData.step == 'Awaiting_approval') {
                this.active = 1
            }
            if (this.OrderData.step == 'Under_study') {
                this.active = 2
            }
            if (this.OrderData.step == 'Price_approval') {
                this.active = 3
            }
            if (this.OrderData.step == 'Payment') {
                this.active = 4
            }
            if (this.OrderData.step == 'Verify_payment') {
                this.active = 5
            }
            if (this.OrderData.step == 'In_progress') {
                this.active = 6
            }
            if (this.OrderData.step == 'Delivered') {
                this.active = 7
            }
            console.log('OrderData:', this.OrderData)
        });
        this.getLogs();
    },
    methods: {
        getLogs() {
            axios.get(`/user/service-requests/logs/${this.$route.params.id}`, {
                headers: {
                    "Authorization": `Bearer ${cookie.get('Token')}`
                }
            })
                .then((res) => {
                    this.Logs = res.data.data;
                })
                .catch(function (error) {
                    notify({
                        type: "error",
                        text: error.response.data.message,
                    });
                });
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear(); // Full year
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day

            return `${year}/${month}/${day}`;
        }
    }
}
</script>